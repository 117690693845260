import { Input as InputWrapper } from 'antd';
import React from 'react';
import Typography from '../Typography';

import './Input.less';

export default function Input({
  type,
  size,
  disabled,
  onChange,
  value,
  placeholder,
  required,
  status,
  errorMessage,
  regexReplace,
  prefix,
}) {
  return (
    <div className="joinplace-input">
      <InputWrapper
        allowClear
        disabled={disabled}
        type={type}
        size={size}
        status={status}
        placeholder={placeholder}
        prefix={prefix}
        onInput={(event) => {
          event.preventDefault();
          event.stopPropagation();
          event.target.value = event.target.value.replace(new RegExp(regexReplace), '');
        }}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={value}
      />
      {errorMessage && (
        <div>
          <Typography
            type="h9"
            style={{ color: '#D74039' }}
          >
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
}
