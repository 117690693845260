export const DEFAULT = {
  id: null,
  tenant_id: null,
  tenant: {},
  company_id: null,
  company: {},
  project_id: null,
  project: {},
  parent_id: null,
  parentFolder: {},
  reference_id: null,
  link: '',
  name: '',
  description: null,
  project_items_s3_keys: [],
  created_by: null,
  created_at: null,
  updated_at: null,
};