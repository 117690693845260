import React from 'react';
import { Card as CardWrapper } from 'antd';

import './Card.less';

export default function Card({ width, children, className, ...props }) {
  return (
    <CardWrapper
      style={{ width }}
      {...props}
      bordered={false}
      className={`joinplace-card ${className}`}
    >
      {children}
    </CardWrapper>
  );
}
