export const PUBLIC_PATHS = {
  images: {
    joinplaceLogo: '/logo.png',
    defaultProjectImage: '/images/default_project_image.png',
    poweredByCorelab: '/powered_by_corelab.svg',
  }
}

export const COPYRIGHT_YEAR = 2024;

export const DEBOUNCE_TIME = 1000;

export const DEFAULT_FORMATS = {
  'pt-BR': {
    PERCENTUAL: '0,0.00 %',
    NUMBER: '0,0.00',
    CURRENCY: '$ 0,0.00',
    AMOUNT: '0 a',
    DATE: 'DD/MM/YYYY',
    FULL_DATE: 'DD [de] MMMM [de] YYYY [às] HH:mm',
    DATE_WITH_HOUR: 'DD/MM/YYYY [às] HH:mm',
    BALANCE_SHEET_DATE: 'MM/YYYY',
    DATE_TIME: 'DD/MM/YYYY HH:mm',
    TIMEZONE: 'America/Sao_Paulo',
  },
};

export const DEFAULT_FORMAT = {
  ...DEFAULT_FORMATS['pt-BR'],
  DATE_RECORD: 'YYYY-MM-DD',
  TIME: 'HH:mm',
  DELIMITER_DATE: '@',
  FILTER_DAYS: 20,
  FILTER_DAYS_START: 30,
  FILTER_DAYS_END: 0,
  ITEMS_LIMIT: 100,
  PAGINATION_LIST: [10, 100, 150, 200, 250, 300],
};

export const FILE_TYPES = {
  folder: 'folder',
};

export const ATTACHMENT_TYPES = ['archive/pdf'];
