/* eslint-disable import/no-anonymous-default-export */
export default {
  'project.new': 'Novo projeto',
  'project.edit': 'Editar projeto',
  'project.delete': 'Excluir projeto',
  'project.add.title': 'Crie um novo projeto',
  'project.update.title': 'Atualize o projeto',
  'project.name': 'Nome do projeto',
  'project.description': 'Descrição do projeto',
  'project.details': 'Detalhes',
  'project.apply': 'Aplicar',
  'project.name.message': 'Por favor, insira o nome do projeto',
  'project.description.message': 'Por favor, insira a descrição do projeto',
  'project.stage': 'Status',
  'project.stage.message': 'Por favor, selecione um status',
  'project.workNumber': 'Código do projeto',
  'project.workNumber.message': 'Por favor, insira o código do projeto',
  'project.workNumber.exists':
    'Já existe um projeto com o mesmo código. Deseja cadastrar mesmo assim?',
  'project.revision': 'Estilo de revisão aceita',
  'project.revision.styles': 'Estilos de revisões permitidas',
  'project.revision.message': 'Selecione um estilo de revisão',
  'project.revision.numeric': 'Númerica (Ex.: -R00; -R36; -R87)',
  'project.revision.doubleNumeric': 'Dupla Númerica (Ex.: -R00.00; -R00.01; -R01.00)',
  'project.revision.alphabetical': 'Alfabética (Ex.: -RA; -RY; -RAG)',
  'project.revision.alert': 'O estilo de revisão não pode ser alterado após a criação do projeto.',
  'project.link': 'Link',
  'project.forum': 'Fórum',
  'project.company': 'Empresa',
  'project.avatar': 'Avatar',
  'project.form.data': 'Dados do projeto',
  'project.form.access': 'Acessos do projeto',
  'project.company.message': 'Por favor, selecione uma empresa',
  'project.already.created': 'Projeto já criado, por favor escolha outro nome',

  'project.stage.IN PROGRESS': 'Em andamento',
  'project.stage.INACTIVE': 'Inativo',
  'project.stage.DELIVERED': 'Entregue',
  'project.stage.CHANGING': 'Em alteração',

  'project.stage.mode.message': 'Selecione um Status',

  'project.emptyDataMessage.message': 'Olá, ainda não há nada por aqui.',
  'project.emptyDataMessage.instructions': 'Você pode iniciar criando um novo projeto!',
  'project.emptyDataMessage.newProject': '+ Novo projeto',
};
