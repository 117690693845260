import { Button as ButtonWrapper } from 'antd';
import React from 'react';

import './Button.less';

export default function Button({ className, children, color, ...props }) {
  const colorButton = `btn-${color || 'default'}`;
  return (
    <ButtonWrapper
      className={["joinplace-button", colorButton, className]}
      {...props}
    >
      {children}
    </ButtonWrapper>
  );
}
