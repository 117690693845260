/* eslint-disable import/no-anonymous-default-export */

import error from './pt-BR/error';
import exception from './pt-BR/exception';
import field from './pt-BR/field';
import general from './pt-BR/general';
import project from './pt-BR/project';
import projectItem from './pt-BR/projectItem';

export default {
  ...general,
  ...field,
  ...project,
  ...projectItem,
  ...error,
  ...exception,
};
