/* eslint-disable import/no-anonymous-default-export */
export default {
  'field.name': 'Nome',
  'field.description': 'Descrição',
  'field.status': 'Status',
  'field.created_at': 'Criado em',
  'field.created_by': 'Criado por',
  'field.updated_at': 'Atualizado em',
  'field.updated_by': 'Atualizado por',
  'field.revision': 'Revisão',
  'field.active.true': 'Ativo',
  'field.active.false': 'Inativo',
  'field.search': 'Pesquisar',
};
