/* eslint-disable import/no-anonymous-default-export */
export default {
  'error.message.user_does_not_exist': 'Usuário não existe',
  'error.message.username_not_found': 'E-mail não encontrado',
  'error.message.wrong_password': 'Senha incorreta',
  'error.message.user_inactive': 'Usuário inativo',
  'error.message.missing_params': 'Parâmetros inválidos, verifique!',
  'error.message.user_trial_time_expired': 'Tempo de avaliação do usuário está expirado',
  'error.message.integration_already_exists': 'Já existe uma integração entre esses projetos',
  'error.message.integration_project_from_the_same_company':
    'Integração de projetos da mesma empresa',
  'error.message.project_already_exists': 'Já existe um projeto com esse nome',
  'error.message.projectTemplate_already_exists': 'Já existe um modelo de projeto com esse nome',
  'error.message.speciality_already_exists': 'Já existe uma especialidade com esse nome',
  'error.message.tenant_trial_time_expired': 'Tempo de avaliação da empresa está expirado',
  'error.message.download_not_available': 'Donwload não disponível',
  'error.message.folder_not_created': 'Ocorreu um erro na criação da pasta',
  'error.message.error_on_upload': 'Error ao carregar arquivo',
  'error.message.folder_already_exists': 'Esta pasta já existe',
  'error.message.folder_not_found': 'Pasta não encontrada',
  'error.message.file_not_found': 'Arquivo não encontrado',
  'error.message.file_not_created': 'Ocorreu um erro na criação do arquivo',
  'error.message.error': 'Ocorreu algum erro',
  'error.message.file_already_exists': 'Este arquivo já existe',
  'error.message.entity_already_saved': 'Entidade já possui acesso',
  'error.message.invalid_current_password': 'Senha atual inválida',
  'error.status.400': 'Requisição inválida',
  'error.status.401': 'Não autorizado',
  'error.status.403': 'Proibido',
  'error.status.404': 'Não encontrado',
  'error.status.500': 'Erro interno do servidor',
  'error.status.502': 'Falha de comunição com o servidor',
  'error.status.503': 'Serviço indisponível',
  'error.status.504': 'Comunicação com o servidor expirou',
};
