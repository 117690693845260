/* eslint-disable import/no-anonymous-default-export */
export default {
  copyright: 'Copyright',
  'navBar.lang': 'Idiomas',
  'layout.user.link.help': 'ajuda',
  'layout.user.link.privacy': 'política de privacidade',
  'layout.user.link.terms': 'termos de serviços',
  'app.home.introduce': 'Ajuda',
  'app.forms.basic.title': 'Formulário básico',
  'app.forms.basic.description':
    'Páginas de formulário são usadas para coletar e verificar as informações dos usuários e formulários básicos são comuns nos cenários onde existem alguns formatos de informações.',

  areYouSureYouWantToAccept: 'Tem certeza que deseja aceitar?',
  areYouSureYouWantToRemove: 'Tem certeza que deseja apagar?',
  areYouSureYouWantToEdit: 'Tem certeza que deseja editar?',
  areYouSureYouWantToClone: 'Tem certeza que deseja clonar?',
  areYouSureYouWantToUndo: 'Tem certeza que deseja desfazer?',
  areYouSureYouWantToCancel: 'Tem certeza que deseja cancelar?',
  dataRecoveredSuccessfully: 'Dados recuperados com sucesso',
  'yes.IWant': 'Sim, eu quero',
  youDontHavePermission: 'Você não tem permissão para realizar esta ação.',
  loading: '...',
  pleaseWait: 'Por gentileza, aguarde',
  ok: 'Ok',
  others: 'Outro(s)',
  number_of_visitors: 'Número de clientes',
  transfer: 'Transferir',
  hasten: 'Antecipar (saque)',
  'loading-more': 'Carregar mais',
  version: 'Versão',
  shortVersion: 'v',
  refresh: 'Atualizar',
  search: 'Buscar',
  continue: 'Continuar',
  edit: 'Editar',
  cancel: 'Cancelar',
  close: 'Fechar',
  submit: 'Salvar',
  status: 'Status',
  reset: 'Limpar',
  options: 'Opções',
  settings: 'Configurações',
  general: 'Geral',
  email: 'E-mail',
  cellphone: 'Celular',
  telephone: 'Telefone',
  create: 'Criar',
  update: 'Atualizar',
  delete: 'Excluir',
  clone: 'Clonar',
  resendEmail: 'Reenviar email',
  language: 'Idioma',
  all: 'Todos',
  'picture.upload': 'Upload',
  'picture.remove': 'Remover imagem',
  'picture.onDrag': 'Solte Aqui',
  add: 'Adicionar',
  balanceTotal: 'Saldo Total',
  balanceNotConciliationTotal: 'Saldo Total',
  balanceConciliationTotal: 'Saldo Conciliado',
  balanceNotRevenueTotal: 'Previsto',
  balanceRevenueTotal: 'Realizado',
  enabled: 'Ativo',
  disabled: 'Inativo',
  createdAt: 'Criado em',
  updatedAt: 'Atualizado em',
  acceptedAt: 'Aceito em',
  canceledAt: 'Cancelado em',
  createdBy: 'Criado pelo(a)',
  updatedBy: 'Atualizado pelo(a)',
  acceptedBy: 'Aceito pelo(a)',
  requestBy: 'Solicitado pelo(a)',
  canceledBy: 'Cancelado pelo(a)',
  dateFormat: 'DD/MM/YYYY',
  statement: 'Extrato',
  until: 'até',
  today: 'Hoje',
  yesterday: 'Ontem',
  lastFortnight: 'Últimos 15 dias',
  nextFortnight: 'Próximos 15 dias',
  thisMonth: 'Este Mês',
  thisYear: '1 Ano (A partir de hoje)',
  goBack: 'Voltar',
  calendar: 'Agenda',
  lastUpdate: 'Última atualização',

  filteredBy: 'Filtrado por',
  filter: 'Filtrar',
  'filter.clean': 'Limpar Filtros',

  'link.expired': 'Link expirado',

  mail_sent: 'E-mail enviado',
  mail_sent_to: 'E-mail enviado para',
  'send.to.mail': 'Envio por E-mail',
  'recovery.with.success': 'Atualizado com sucesso!',
  'follow.procedure.sent.email': 'Siga o procedimento enviado ao e-mail',
  'button.create': 'Criar',
  'button.new': 'Novo',
  'button.new.link': 'Novo link',
  'button.add': 'Adicionar',
  'button.add.and.choice': 'Adicionar e Usar',
  'button.update': 'Atualizar',
  'button.update.and.choice': 'Atualizar e Usar',
  'button.select': 'Selecionar',
  'button.select.recipient': 'Selecionar destinatário(os)',
  'button.send': 'Enviar',
  'button.share': 'Compartilhar',
  'button.download': 'Download',
  'button.download.loadingMessage':
    'Seu arquivo está sendo processado, quando estiver pronto será disponibilizado para você (não recarregue a página)',
  'button.view': 'Visualizar',
  'button.copy': 'Copiar',
  'button.copy.success': 'Copiado com sucesso',
  'button.copy.link': 'Copiar link',
  'button.copy.link.success': 'Link copiado com sucesso',

  'clean.selected': 'Limpar seleção',
  'items.selected': 'Item(ns) selecionado(s) ',
  'yesno.true': 'Sim',
  'yesno.false': 'Não',
  'yes.confirm': 'Confirmar',
  'no.cancel': 'Cancelar',
  'no.close': 'Fechar',
  'yes.finish': 'Concluir',
  'joinplace.welcome': 'Gerenciando PROJETOS, construindo sonhos.',
  'joinplace.slogan': 'Todos os seus projetos se encontram aqui',
  'joinplace.slogan.register': 'Seu cadastro está sendo criado',
  'joinplace.welcome.register': 'Construindo sonhos...',
  'joinplace.aboutus': 'Sobre a Joinplace',
  'joinplace.benefits': 'Vantagens',
  'joinplace.visitus': 'Visite nosso site',

  production: 'Produção',
  homologation: 'Homologação',
  'normalizeRule.SOURCE': 'Igual da origem',
  'normalizeRule.NUMERIC': 'Somente números',
  'normalizeRule.ZERO_NUMERIC': 'Somente números e Inclusão do zero inicial',
  'normalizeRule.ZERO': 'Inclusão do zero inicial',

  'provided.conducted': 'Previsto / Realizado',
  service: 'Serviço',
  revenue: 'Receitas',
  received: 'Recebido',
  late: 'Atrasado',
  expenses: 'Despesas',
  paidOut: 'Pago',
  predicted: 'Previsto',
  choose: 'Escolher',

  'modal.close.title': 'Tem certeza que deseja sair?',
  'modal.close.description': 'Caso sair irá perder as informações alteradas sem salvar.',

  nextInvoice: 'Próxima fatura',
  'nextInvoice.message': 'O próximo boleto estará disponível no dia ',

  total: 'Total',
  value: 'Valor',
  date: 'Data',

  next: 'Próximo',
  previous: 'Anterior',
  done: 'Finalizar',
  export: 'Exportar',
  import: 'Importar',

  empty: ' ',

  'required.message': 'é necessário',
  'insert.message': 'Por favor, insira o',

  I: 'Eu, {name},',
  'terms.confirm.description.terms': `confirmo que li e aceito os`,
  'terms.confirm.description.privacy': `e a`,
  'terms.confirm.description.joinplace': `da plataforma Joinplace`,

  'resend.mail.success.message': 'Email enviado com sucesso.',
  'resend.mail.error.message': 'Ocorreu um erro ao enviar o email.',

  without_message: 'Sem Informações',

  file: 'Arquivo',
  'upload.click-or-drag-file-text': 'Clique ou arraste o arquivo para esta área para fazer upload',
  'upload.click-or-drag-file-cert.hint': 'Suporte para upload único com extensões .pfx ou .pcs12',
  'upload.click-or-drag-file-ofx.hint': 'Suporte para upload único com extensão .ofx',
  'upload.click-or-drag-file-default.hint':
    'Suporte para upload único ou vários com extensões .xls, .xlsx, .doc, .docx, .pdf, .png, .jpg, .txt',

  'upload.failed.message': 'falhou no upload',
  'upload.success.message': 'enviado com sucesso',
  'upload.removed.message': 'removido com sucesso',
  'upload.failed.removed.message': 'falhou ao ser deletado',

  'btnShowCurrency.true': 'Ocultar valores',
  'btnShowCurrency.false': 'Mostrar valores',

  'taxNumber.choose.type': 'Escolha o tipo de pessoa',
  'taxNumber.validate.PP.message': 'Por favor, informe um CPF válido.',
  'taxNumber.validate.LP.message': 'Por favor, informe um CNPJ válido.',
  'email.invalid.message': 'Por favor, informe um e-mail válido.',

  'nickname.validate.character': 'Deve conter apenas caracteres especiais . _ -',
  'nickname.validate.space': 'Não pode conter espaços em branco.',
  'nickname.validate.smallLetters': 'Deve conter apenas letras minúsculas.',
  'nickname.validate.maxLenght': 'Deve conter no máximo 32 caracteres.',
  store: 'Armazenamento',
  users: 'Usuários',
  in: 'de',
  used: 'usados',
  'page.title.projectItemList': 'Itens Compartilhados',
};
