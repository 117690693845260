import {
  CopyrightCircleOutlined,
  FileImageFilled,
  FileUnknownFilled,
  FolderFilled,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import './Icon.less';

export const ICONS = {
  minusCircle: {
    slug: 'minusCircle',
    component: MinusCircleOutlined,
  },
  plusCircle: {
    slug: 'plusCircle',
    component: PlusCircleOutlined,
  },
  copyrightCircle: {
    slug: 'copyrightCircle',
    component: CopyrightCircleOutlined,
  },
  folderFilled: {
    slug: 'folderFilled',
    component: FolderFilled,
  },
  search: {
    slug: 'search',
    component: SearchOutlined,
  },
  'fileUnknown': {
    slug: 'fileUnknown',
    component: FileUnknownFilled,
  },
  'png': {
    slug: 'png',
    component: FileImageFilled,
  },
  'jpeg': {
    slug: 'jpeg',
    component: FileImageFilled,
  },
};

const SIZE = {
  tiny: 14,
  small: 24,
  default: 32,
  large: 40,
};

export default function Icon({
  size,
  type,
  color,
  dimensionDefault,
  className,
  style = {},
  ...props
}) {
  const Component = ICONS[type].component;
  const dimension = SIZE[size] || SIZE.default;
  const textColor = `text-${color || 'default'}`;
  if (!Component) return null;

  return (
    <Component
      className={[textColor, className]}
      style={{ fontSize: dimension, ...style }}
      {...props}
    />
  );
}
