import { useState, useEffect } from 'react';
import {
	getItem,
	setItem,
	deleteItem,
	clearItem
} from '../services/localStorage';

export function useLocalStorage(key, initialValue) {
	const [loading, setLoading] = useState(true);
	const [storedValue, setStoredValue] = useState(initialValue);

	const setValue = (valueToStore) => {
		try {
			setStoredValue(valueToStore);
			const value = valueToStore;
			if (!value) return deleteItem(key);

			setItem(key, value);
		} catch (error) {
			console.error(error);
		}
	};

	const getStorageItem = async () => {
		try {
			const item = await getItem(key);
			const newValue = item || initialValue;
			setStoredValue(newValue);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const clear = async () => {
		clearItem();
		setStoredValue(initialValue);
		setLoading(false);
	};

	const remove = async () => deleteItem(key);

	useEffect(() => {
		getStorageItem();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [storedValue, setValue, loading, remove, clear];
}
