import React from 'react';
import { Select as SelectWrapper } from 'antd';
import Typography from '../Typography';

import './Select.less';

const { Option } = SelectWrapper;

export default function Select({ size, disabled, onChange, value, options, placeholder, required }) {
  return (
    <div className="joinplace-select">
      <Typography type="h9" style={{ display: 'flex', alignItems: 'center' }}>
        {required 
          ? <div style={{ width: '5px', height: '5px', borderRadius: '50%', background: '#D74039' }}/> 
          : <></> 
        }
        {placeholder}
      </Typography>
      <SelectWrapper
        showSearch
        allowClear
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={(value) => onChange(value)}
        defaultValue={value}
        dropdownClassName="joinplace-dropdown"
        filterOption={(input, { props }) =>
          props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options?.map(({ name, value }) => (
          <Option key={value} value={value}>{name}</Option>
        ))}
      </SelectWrapper>
    </div>
  );
}
