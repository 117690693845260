/* eslint-disable jsx-a11y/alt-text */
import { Skeleton, notification } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Header, Layout, LayoutContent, Loading, Routing } from './components';
import FooterComponent from './components/Footer';
import { ROUTES } from './config/routes';
import { DEFAULT } from './constants/proporsal';
import { useLocalStorage } from './hooks/useLocalStorage';
import Api from './services/api';

import './App.less';

moment.locale('pt');
numeral.locale('pt-br');

const Page404 = React.lazy(() => import('./pages/Page404'));

function App() {
  const [loading, setLoading] = useState(false);

  const { pathname: link = '' } = window.location;
  const [, linkId, parentId] = link.split('/');

  const errorPage = link.includes('error') || !linkId;
  const [data, setData] = useLocalStorage(
    `projectItemLinkSharing-${linkId}`,
    DEFAULT,
  );

  const onReset = () => setData(DEFAULT);

  const onChangeData = (event) => setData({ ...data, ...event });

  const onChangeField = (field, event) =>
    setData({ ...data, [field]: { ...data[field], ...event } });

  const openNotification = (message = '', description = '', props = {}) =>
    notification.open({ message, description, ...props });

  const getLink = async () => {
    if (errorPage) return;
    setLoading(true);

    try {
      const sharingLinkData = await Api.projectItemSharingLinkData(linkId);
      const { data } = sharingLinkData || {};
      if (!data) return;
      onChangeData(data);
      if (data.parent_id && !parentId) window.location.href = `${linkId}/${data.parent_id}`;
    } catch (err) {
      localStorage.clear();
      window.location.href = '/error';
    }

    setLoading(false);
  };

  useEffect(() => {
    setData(data);
    getLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Skeleton active />;
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Layout className="App">
          <LayoutContent>
            <Header
              data={data}
              footer={FooterComponent}
            >
              <Switch>
                {!errorPage && ROUTES.map(({ path, title, component }) => (
                  <Routing
                    key={path}
                    path={path}
                    component={component}
                    title={title}
                    exact={true}
                    struct={{
                      data,
                      parentId,
                      onReset,
                      onChangeData,
                      onChangeField,
                      openNotification,
                    }}
                  />
                ))}
                <Routing path="/error" component={Page404} title="Erro" />
                <Routing path="*" component={Page404} title="Home" />
              </Switch>
            </Header>
          </LayoutContent>
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
