/* eslint-disable import/no-anonymous-default-export */
export default {
  'projectItem.name': 'Nome',
  'projectItem.version': 'Revisão',
  'projectItem.owner': 'Proprietário',
  'projectItem.description': 'Descrição',
  'projectItem.updated_at': 'Data de Atualização',
  'projectItem.created_at': 'Data',
  'projectItem.download': 'Baixar',
  'projectItem.delete': 'Excluir',
  'projectItem.folderRoot': 'Pasta Raiz',
  'projectItem.new_folder': 'Nova Pasta',
  'projectItem.upload': 'Upload',
  'projectItem.add': 'Adicionar Arquivo',
  'projectItem.update': 'Atualizar',
  'projectItem.input.remove': 'Remover',
  'projectItem.input.required': 'Campo obrigatório',
  'projectItem.menuItem.Download': 'Download',
  'projectItem.menuItem.share': 'Compartilhar',
  'projectItem.menuItem.details': 'Detalhes',
  'projectItem.menuItem.view': 'Visualizar',
  'projectItem.menuItem.personalize': 'Personalizar',
  'projectItem.menuItem.personalize.folderColor': 'Cor da pasta',
  'projectItem.search': 'Pesquisar',
  'projectItem.listFile': 'Lista de Arquivos',
  'projectItem.revision.place.extern': 'Revisão externa',
  'projectItem.revision.numeric.help':
    'A revisão dos arquivos devem seguir obrigatoriamente o padrão R00, R01, R99.',
  'projectItem.revision.alphabetical.help':
    'A revisão dos arquivos devem seguir obrigatoriamente o padrão RA, RB, RCZ.',
  'projectItem.revision.doubleNumeric.help':
    'A revisão dos arquivos devem seguir obrigatoriamente o padrão R00.00, R01.01, R99.99.',
  'projectItem.validation.description': 'Informe a descrição',
  'projectItem.validation.revision': 'Informe a revisão',
  'projectItem.placeholder.description': 'Descrição do conteúdo',
  'projectItem.placeholder.description.file': 'Descrição do arquivo',
  'projectItem.placeholder.description.folder': 'Descrição da pasta',
  'projectItem.modal.send': 'Enviar',
  'projectItem.modal.cancel': 'Cancelar',
  'projectItem.modal.revisionInfo':
    'Ao cadastrar um arquivo com uma nova revisão, será substituído automaticamente',
  'projectItem.error': 'Ocorreu algum erro',
  'projectItem.upload.dropText': 'Arraste os arquivos para cá',
  'projectItem.upload.dropHint': 'Ou clique aqui e procure',
  'projectItem.pathTaken': 'Caminho percorrido',
  'projectItem.drawerDetails.title': 'Detalhes',
  'projectItem.drawerDetails.folder': 'Detalhes da pasta',
  'projectItem.drawerDetails.folderName': 'Nome da pasta',
  'projectItem.drawerDetails.files': 'Detalhes do arquivo',
  'projectItem.drawerDetails.fileName': 'Nome do arquivo',
  'projectItem.drawerDetails.owner': 'Proprietário',
  'projectItem.drawerDetails.from': 'Por',
  'projectItem.drawerDetails.modified': 'Modificado em',
  'projectItem.drawerDetails.lastVersions': 'Últimas versões',
  'projectItem.drawerDetails.historic': 'Histórico',
  'projectItem.drawerDetails.ative': 'Ativo',
  'projectItem.drawerDetails.copy': 'Copiado',
  'projectItem.drawerDetails.downloadLastVersion': 'Baixar última versão',
  'projectItem.drawerDetails.download.withoutRevision':
    'A revisão não será incluída no nome do arquivo usando este download.',
  'projectItem.error.maxNameLength': 'Nome com tamanho acima do limite de caracteres.',
};
