import { Col, PageHeader, Row } from 'antd';
import React from 'react';
import { withRouter } from 'react-router';
import { PUBLIC_PATHS } from '../../utils/constant';
import './Header.less';

function Header({ children, footer }) {
  return (
    <PageHeader
      className="header-fixed"
      key="pageheader"
      title={
        <Row>
          <Col>
            <img
              className="logo_avatar"
              title='joinplacelogo'
              alt='joinplacelogo'
              src={PUBLIC_PATHS.images.joinplaceLogo}
            />
          </Col>
        </Row>
      }
      footer={footer()}
    >
      {children}
    </PageHeader>
  );
}

export default withRouter(Header);
