import ptBR from "../locales/pt-BR";

const LANGUAGES = {
  'pt-BR': ptBR
};

const formatMessage = (slug, lanquage = 'pt-BR') => LANGUAGES?.[lanquage]?.[slug];

const sanitize = (text) => {
  return (text || '')
    .replace(/\//gi, '')
    .replace(/ /gi, '')
    .replace(/\./gi, '')
    .replace(/-/gi, '')
    .replace(/_/gi, '')
    .replace(/\(/gi, '')
    .replace(/\)/gi, '');
}

const sanitizeRG = (text) => {
  return (text || '')
    .replace(/ /gi, '')
    .replace(/\./gi, '')
    .replace(/-/gi, '')
    .replace(/_/gi, '')
    .replace(/\(/gi, '')
    .replace(/\)/gi, '');
}

export {
  LANGUAGES,
  formatMessage,
  sanitize,
  sanitizeRG
};

