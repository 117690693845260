import axios from 'axios';
import { stringify } from 'qs';

const { BACKEND_URL } = process.env;

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const Api = axios.create({
  baseURL: `${BACKEND_URL}/api/joinWebLink`,
  timeout: 0,
  withCredentials: true,
  mode: 'no-cors',
  crossDomain: true,
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Allow-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'x-requested-with'
  }
});

const projectItemSharingLinkData = async referenceId => {
  return Api.get(`/${referenceId}`);
}

const projectItemsPaginate = async data => {
  return Api.get(`/paginateItems?${stringify(data)}`);
}

const projectItemDownload = async itemId => {
  return Api.get(`/download/item/${itemId}`);
}

const projectItemsDownload = async data => {
  return Api.get(`/download/items?${stringify(data)}`, {
    responseType: 'blob'
  });
}

const itemViewLink = itemId => {
  return `${BACKEND_URL}/api/itemview/${itemId}`;
}

const Http = {
  projectItemSharingLinkData,
  projectItemsPaginate,
  projectItemDownload,
  projectItemsDownload,
  itemViewLink,
};

export default Http;
