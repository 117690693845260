/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Layout } from 'antd';
import { COPYRIGHT_YEAR, PUBLIC_PATHS } from '../../utils/constant';
import { formatMessage } from '../../utils/text';
import './Footer.less';

const { Footer } = Layout;

function FooterComponent() {
  return (
    <Footer className='page-footer'>
      <img src={PUBLIC_PATHS.images.poweredByCorelab} width={150} /><br />
      {formatMessage('copyright')} {COPYRIGHT_YEAR}
    </Footer>
  );
}

export default FooterComponent;
